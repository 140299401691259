<template>
  <div id="profile-page" class="user-form-admin">
    <vx-card class="accordin-card mb-5">
      <vs-collapse accordion>
        <vs-collapse-item open>
          <div slot="header">
            <h3 class="text-left card-title font-normal">
              Users role <span class="mid-blue text-2xl">*</span>
            </h3>
          </div>
          <div class="grid grid-cols-3 gap-5">
            <radio-box
              title="Admin"
              name="role"
              description="Full access including editing of global account settings"
              id="role"
              radioValue="admin"
              value="role"
              v-validate="'required'"
              data-vv-as="User role"
              v-model="user.userType"
              @input="handleChange"
            ></radio-box>

            <radio-box
              title="Operations"
              name="role"
              id="role"
              v-validate="'required'"
              description="Granted permissions based on user groups they are added to"
              radioValue="operationAdmin"
              value="role"
              data-vv-as="User role"
              v-model="user.userType"
              @input="handleChange"
            ></radio-box>

          </div>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>

    <vx-card class="accordin-card">
      <vs-collapse accordion>
        <vs-collapse-item open>
          <div slot="header">
            <h3 class="text-left card-title font-normal">
              Users details <span class="mid-blue text-2xl">*</span>
            </h3>
          </div>
          <div class="grid grid-cols-2 gap-x-5">
            <div>
              <label for="fullName" class="w-full text-xm font-normal">Full Name <span class="required-text">*</span></label>
              <vs-input size="large" v-model="user.fullName" data-vv-validate-on="blur" data-vv-as="full name" v-validate="'required|max:100'" name="fullName"
                id="fullName" class="w-10/12"/>
              <span class="text-danger text-sm py-3 block" v-show="errors.has('fullName')">{{ errors.first("fullName") }}</span>
            </div>

            <div>
              <label for="email" class="w-full text-xm font-normal">Email address <span class="required-text">*</span></label>
              <vs-input size="large" v-model="user.email" data-vv-as="email address" v-validate="'required|email'" name="email" id="email" class="w-10/12"/>
              <span class="text-danger text-sm py-3 block" v-show="errors.has('email') && fields.email.touched">{{ errors.first("email") }}</span>
            </div>
          </div>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>

    <delete-popup
      :popup-active="popupDelActive"
      :warningContent="warningContent"
      :deleteWhat="deleteWhat"
      :buttonText="buttonText"
      :found="warningFound"
      @handleDeleteClick="delActions"
    ></delete-popup>

    <pop-up :errorsList="errors" form-name="UserForm"></pop-up>

    <vs-col class="flex justify-end lg:mt-5 sm:mt-4">
      <vs-button class="mx-3 only-border-btn" size="large" v-round color="primary" v-if="$route.query.id && selecteduser.userType !=='superAdmin'" @click="popupDelActive = true">Delete User</vs-button>
      <vs-button class="mx-5" size="large" v-round color="primary" v-if="!$route.query.id" :disabled="user.fullName.length == 0 || user.email.length == 0" @click="saveUser">Add User</vs-button>
      <vs-button class="mx-5" size="large" v-round color="primary" v-else @click="editUser" :disabled="!validateForm || (!isFormDirty && !roleChanged)">Save User</vs-button>
      <a class="flex items-center underline mid-blue" @click="$router.go(-1)">Cancel</a>
    </vs-col>
  </div>
</template>

<script>
import { PlusIcon } from "vue-feather-icons";

import RadioBox from "@/views/components/radiobox";
import DeletePopup from "@/views/components/DeletePopup";
import PopUp from "@/views/components/ValidationPopup.vue";
import { mapActions } from "vuex";

export default {
  name: "UserForm",

  components: {
    PlusIcon,
    PopUp,
    RadioBox,
    DeletePopup,
  },

  data() {
    return {
      user: {
        fullName: "",
        email: "",
        userType: "",
      },
      popupDelActive: false,
      warningContent:
        "By deleting this user you are permanently removing their access to this platform.",
      deleteWhat: `user`,
      buttonText: `Delete`,
      warningFound: false,
      selecteduser: '',
      roleChanged: false,
    };
  },

  computed: {
    validateForm() {
      return !this.errors.any();
    },
  },

  methods: {
    ...mapActions("admin", [
      "checkLogin",
      "createAdminUser",
      "getAdminUser",
      "updateAdminUserDetailsById",
      "deleteAdminUserById",
    ]),

    async checkAdminLogin() {
      await this.checkLogin().then(res => {
        if (!res.data.data.permissions["users"]) {
          this.$router.push({ name: "dashboard" });
        }
      });
    },

    delActions(event) {
      switch (event) {
        case "delete":
          this.popupDelActive = false;
          this.deleteUser();
          break;
        case "cancel":
          this.popupDelActive = false;
          break;
        default:
          this.popupDelActive = false;
      }
    },

    async deleteUser() {
      this.$vs.loading();

      await this.deleteAdminUserById(this.$route.query.id).then((result) => {
        this.$vs.loading.close();
        this.showMessage("Successful", "User has been deleted successfully.", "success", "icon-check-circle");
        this.$router.go(-1);
      }).catch((ex) => {
        this.$vs.loading.close();
        this.showMessage("Error", ex.data.message, "danger", "icon-times");
      });
    },
    async editUser() {
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          this.$vs.loading();
          if(this.selecteduser.userType === "superAdmin"){
            this.user.userType = "superAdmin";
          }
          const payload = {
            data: this.user,
            id: this.$route.query.id,
          };
          await this.updateAdminUserDetailsById(payload).then((result) => {
            this.$vs.loading.close();
            this.showMessage("Successful", "User has been updated successfully.", "success", "icon-check-circle");
            this.$router.push({name:'user-list'});
          }).catch((ex) => {
            this.$vs.loading.close();

            if (ex.status == 422 && ex.data.title === "Email") {
              this.errors.add({
                field: "email",
                msg: ex.data.message,
              });
            }

            this.showMessage("Error", ex.data.message, "danger", "icon-times");
          });
        }
      });
    },
    async saveUser() {
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          this.$vs.loading();
          await this.createAdminUser(this.user).then((result) => {
            this.$vs.loading.close();
            this.showMessage(
              "Successful",
              "User has been created successfully.",
              "success",
              "icon-check-circle"
            );
            this.$router.push({name:'user-list'});
          }).catch((ex) => {
            this.$vs.loading.close();

            if (ex.status == 422 && ex.data.title === "Email") {
              this.errors.add({
                field: "email",
                msg: ex.data.message,
              });
            }

            this.showMessage("Error", ex.data.message, "danger", "icon-times");
          });
        }
      });
    },

    async getUser() {
      this.$vs.loading();
      await this.getAdminUser(this.$route.query.id).then((result) => {
        this.$vs.loading.close();
        this.selecteduser = result.data.data;
        this.user = {...result.data.data};
        if(this.selecteduser.userType == 'superAdmin') {
          this.user.userType = 'admin';
        }
      }).catch((ex) => {
        this.$vs.loading.close();
        this.showMessage("Error", "User could not be fetched.", "danger", "icon-times");
      });
    },

    showMessage(title = "Successful", message = "Task completed", color = "success", icon = "icon-check-circle") {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,
      });
    },

    handleChange(){
      this.roleChanged = true;
    }
  },

  async mounted() {
    await this.checkAdminLogin();

    if (this.$route.query.id) {
      this.getUser();
    }
  },
};
</script>
